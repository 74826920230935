import { ChannelType } from '@checkout-ui/shared-context-session';
import { LocaleType } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';

import {
  AuthorizationModalData,
  direct_auth_request,
  DirectAuthResponseEvaluation,
} from '../authorizationDataTypes';
import {
  transformAmount,
  transformAuthorizationEvaluation,
  transformBillingAddress,
  transformDebtorCompany,
  transformDebtorPerson,
  transformDeliveryAddress,
  transformDuration,
  transformLineItems,
  transformSession,
} from './utils';

export const mapModalInternalDataToAuthorizeCall = (
  modalData: AuthorizationModalData
): direct_auth_request &
  Partial<DirectAuthResponseEvaluation> & {
    //FIXME refactor types for more clarity.
    session_context?: { channel?: ChannelType; locale: LocaleType | undefined };
  } => {
  logger.log('mapModalInternalDataToAuthorizeCall :', modalData);

  const {
    purchase_details,
    payment_details,
    debtor_company,
    session_context,
    authorization_evaluation,
  } = modalData;

  if (!purchase_details) {
    throw new Error('invalid purchase_details');
  }

  if (!payment_details) {
    throw new Error('invalid payment_details');
  }

  if (!debtor_company) {
    throw new Error('invalid debtor_company');
  }

  if (!session_context) {
    throw new Error('invalid session_context');
  }

  const delivery_address = transformDeliveryAddress(purchase_details);
  const billing_address = transformBillingAddress(purchase_details);

  return {
    amount: transformAmount(purchase_details),
    comment: purchase_details.comment,
    ...(purchase_details.order_id && { order_id: purchase_details.order_id }),
    duration: transformDuration(payment_details),
    ...(delivery_address && { delivery_address }),
    ...(billing_address && { billing_address }),
    debtor_company: transformDebtorCompany(debtor_company),
    debtor_person: transformDebtorPerson(debtor_company.contact),
    line_items: transformLineItems(purchase_details),
    ...transformAuthorizationEvaluation(authorization_evaluation),
    ...transformSession(session_context),
  };
};
