import { AuthorizationModalData } from '@checkout-ui/shared/data-transformers';

import type { AuthorizeResultError } from '../types';

export type ModalFrame = HTMLIFrameElement;

export type FrameHandlers = {
  onOpen: () => void;
  onClose?: (result: AuthorizationModalData) => void;
  onResult?: (result: AuthorizationModalData) => void;
  onError?: (error: AuthorizeResultError) => void;
};
export enum MODAL_SCENARIOS {
  authorize = 'authorize',
  error = 'error',
}

export type ModalSubscribeEventHandlers = Omit<FrameHandlers, 'onOpen'>;
