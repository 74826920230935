import {
  array,
  boolean,
  func,
  max,
  min,
  nonempty,
  nullable,
  number,
  object,
  optional,
  partial,
  string,
  type,
  union,
} from 'superstruct';

import { AuthorizeData } from './types';

export const mockData: AuthorizeData = {
  amount: { gross: 100, tax: 19, net: 81 },
  comment: '',
  order_id: 'test-external-order-id123',
  duration: 30,
  delivery_address: {
    house_number: '4',
    street: 'Charlottenstr.',
    city: 'Berlin',
    postal_code: '10969',
    country: 'DE',
  },
  billing_address: {
    addition: 'test',
    street: 'Andrea Alfred-Delp-Str.',
    house_number: '62b',
    city: 'Karinaheim',
    postal_code: '59816',
    country: 'DE',
  },
  debtor_company: {
    legal_form: '10001',
    name: 'Billie GmbH',
    address_addition: '',
    address_house_number: '4',
    address_street: 'Charlottenstr.',
    address_city: 'Berlin',
    address_postal_code: '10969',
    address_country: 'DE',
  },
  debtor_person: {
    salutation: 'm',
    first_name: 'John',
    last_name: 'Doe',
    phone_number: '+491522530345',
    email: 'test-qa@billie.io',
  },
  line_items: [
    {
      external_id: 'SKU-001',
      title: 'Generic item',
      description: 'Generic item test purchase',
      quantity: 1,
      category: 'Generic',
      brand: 'Generic',
      gtin: 'Test gtin',
      mpn: 'Test mpn',
      product_url: 'test',
      image_url: 'test',
      type: 'test',
      quantity_unit: 'test',
      tax_rate: 0,
      total_discount_amount: 0,
      unit_price: 0,
      amount: { gross: 100, tax: 19, net: 81 },
    },
  ],
};

const delivery_address = type({
  house_number: optional(nullable(string())),
  street: string(),
  city: string(),
  postal_code: string(),
  country: string(),
  addition: optional(nullable(string())),
});

const billing_address = optional(
  type({
    addition: optional(string()),
    house_number: optional(string()),
    street: string(),
    city: string(),
    postal_code: string(),
    country: string(),
  })
);

const amount = type({
  gross: union([number(), string()]),
  net: union([number(), string()]),
  tax: union([number(), string()]),
});

const debtor_company = type({
  legal_form: optional(string()),
  name: optional(string()),
  address_addition: optional(nullable(string())),
  address_house_number: optional(string()),
  address_street: optional(string()),
  address_city: optional(string()),
  address_postal_code: optional(string()),
  address_country: optional(string()),
  merchant_customer_id: optional(string()),
  registration_number: optional(string()),
  established_customer: optional(boolean()),
});

const debtor_person = type({
  salutation: optional(nullable(string())),
  first_name: optional(string()),
  last_name: optional(string()),
  phone_number: optional(string()),
  email: optional(string()),
});

const line_items = array(
  type({
    external_id: optional(string()),
    title: string(),
    description: optional(nullable(string())),
    quantity: number(),
    category: optional(string()),
    brand: optional(nullable(string())),
    gtin: optional(nullable(string())),
    mpn: optional(nullable(string())),
    product_url: optional(string()),
    image_url: optional(string()),
    type: optional(string()),
    quantity_unit: optional(string()),
    tax_rate: optional(number()),
    total_discount_amount: optional(number()),
    unit_price: optional(number()),
    amount: type({
      gross: union([number(), string()]),
      net: union([number(), string()]),
      tax: union([number(), string()]),
    }),
  })
);

const session_context = type({
  channel: optional(string()),
  confirmed: optional(boolean()),
  locale: optional(string()),
  autoconfirm: optional(boolean()),
});

export const validationSchema = object({
  data: partial(
    object({
      amount,
      comment: optional(string()),
      order_id: optional(string()),
      duration: number(),
      delivery_address,
      billing_address,
      debtor_company,
      debtor_person,
      line_items,
      session_context,
    })
  ),
  callback: optional(func()),
});

export const publicApiValidationSchema = object({
  billie_order_data: type({
    amount,
    comment: optional(string()),
    order_id: optional(string()),
    duration: max(min(number(), 1), 121, { exclusive: true }),
    delivery_address: optional(delivery_address),
    billing_address,
    debtor_company,
    debtor_person,
    line_items: nonempty(line_items),
  }),
});
