import { BillieCheckoutWidgetOrder } from '../../../../types';
import { AuthorizeData } from '../types';
import { recursiveRemoveNulls } from './recursiveRemoveNulls';

export const pickByCondition = <O extends Record<string, unknown>>(
  src: O,
  condition: Partial<Record<keyof O, boolean>>
): Partial<O> => {
  const copy = JSON.parse(JSON.stringify(src));

  for (const prop in condition) {
    if (condition[prop] === false) {
      delete copy[prop];
    }
  }

  return copy;
};

export const cleanupMountCall = (
  order: BillieCheckoutWidgetOrder
): Omit<AuthorizeData, 'options'> => {
  const nonullsOrder = recursiveRemoveNulls(order);

  return {
    ...nonullsOrder,
    amount: {
      gross: Number(order.amount?.gross),
      net: Number(order.amount?.net),
      tax: Number(order.amount?.tax),
    },
    duration: Number(order.duration),
    line_items:
      nonullsOrder.line_items?.map((lineItem) => ({
        ...lineItem,
        quantity: Number(lineItem.quantity),
        amount: {
          gross: Number(lineItem.amount?.gross),
          net: Number(lineItem.amount?.net),
          tax: Number(lineItem.amount?.tax),
        },
        ...pickByCondition(
          {
            external_id: String(lineItem.external_id),
          },
          { external_id: !!lineItem.external_id }
        ),
      })) || [],
    ...pickByCondition(
      {
        debtor_person: pickByCondition(
          {
            ...nonullsOrder.debtor_person,
            phone_number: String(nonullsOrder.debtor_person?.phone_number),
          },
          {
            phone_number:
              nonullsOrder.debtor_person?.phone_number !== undefined,
          }
        ),
      },
      {
        debtor_person: !!nonullsOrder.debtor_person,
      }
    ),
  };
};
