import { Environment } from './types';

export const detectEnvironmentFromScriptSrc = (src: string): Environment => {
  if (src.indexOf('localhost') >= 0) {
    return Environment.Local;
  }

  if (/\.test\d+\.ozean12\.com\//gi.test(src)) {
    return Environment.TestInstance;
  }

  if (src.indexOf('sandbox.billie.io/') >= 0) {
    return Environment.Sandbox;
  }

  return Environment.Production;
};

export { Environment } from './types';
