import { parse } from './parse';
import {
  SdkConfigError,
  SdkConfigInitializationCallbacks,
  SdkConfigInitializationStatus,
  SdkConfigReady,
  SdkConfigState,
} from './types';

const internal: {
  state: SdkConfigState;
  on: SdkConfigInitializationCallbacks;
} = {
  state: {
    status: SdkConfigInitializationStatus.Initial,
    config: {},
  },
  on: {
    ready: new Set(),
    error: new Set(),
  },
};

export const getState = () => internal.state;

export const getConfigUrl = (scriptSrc: string): string => {
  const url = new URL(scriptSrc);

  const prefix = url.pathname.split('/').slice(0, -1);
  const pathname = [...prefix, 'runtime', 'config.json'].join('/');

  return url.origin + pathname;
};

export const loadConfig = async (src: string): Promise<void> => {
  internal.state = {
    status: SdkConfigInitializationStatus.Loading,
    configSrc: src,
    config: {},
  };

  try {
    const config = await fetch(src).then((rs) => {
      if (rs.status >= 400) {
        throw new Error(
          `could not get configuration file (http error ${rs.status})`
        );
      }

      return rs.json();
    });

    internal.state = {
      status: SdkConfigInitializationStatus.Ready,
      configSrc: src,
      config: parse(config),
    };
    internal.on.ready.forEach((callback) =>
      callback(internal.state as SdkConfigReady)
    );
  } catch (error) {
    internal.state = {
      status: SdkConfigInitializationStatus.Error,
      configSrc: src,
      config: {},
      error: error instanceof Error ? error : new Error('unknown error'),
    };
    internal.on.error.forEach((callback) =>
      callback(internal.state as SdkConfigError)
    );

    throw error;
  }
};

export const ensureConfigIsReady = async () => {
  switch (internal.state.status) {
    case SdkConfigInitializationStatus.Initial: {
      throw new Error('config was never requested');
    }
    case SdkConfigInitializationStatus.Loading: {
      return new Promise((resolve, reject) => {
        const handleReady = () => {
          internal.on.ready.delete(handleReady);
          internal.on.error.delete(handleError);
          resolve(undefined);
        };
        const handleError = (errorState: SdkConfigError) => {
          internal.on.ready.delete(handleReady);
          internal.on.error.delete(handleError);
          reject(errorState.error);
        };
        internal.on.ready.add(handleReady);
        internal.on.error.add(handleError);
      });
    }
    case SdkConfigInitializationStatus.Ready: {
      return;
    }
    case SdkConfigInitializationStatus.Error: {
      throw internal.state.error;
    }
  }
};
