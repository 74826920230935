import { logger } from '@checkout-ui/shared-logger';
import * as Sentry from '@sentry/browser';

import { BillieCheckoutWidget } from '../../types';
import { SENTRY_APPLICATION_KEY } from '../constants';
import {
  mount as mountAnalytics,
  setCommonProperties,
  trackWithCommonProperties,
} from './lib/analytics';
import { authorize } from './lib/authorize';
import {
  getConfigUrl,
  getState,
  isSdkConfigReady,
  loadConfig,
} from './lib/config';
import { initialize } from './lib/initialize';
import { mount } from './lib/mount';
import { on } from './lib/on';
import { setScriptSrc } from './lib/state';

logger.setConfig({ name: 'Direct SDK', color: 'darkRed' });

declare global {
  interface Window {
    BillieSDK: {
      authorize: typeof authorize;
      initialize: typeof initialize;
      on: typeof on;
    };
    BillieCheckoutWidget: BillieCheckoutWidget;
    __BILLIE_CHECKOUT_CONFIG__: unknown;
  }
}

window.BillieSDK = {
  authorize,
  initialize,
  on,
};

window.BillieCheckoutWidget = {
  mount,
};

window.__BILLIE_CHECKOUT_CONFIG__ = {};

if (
  document.currentScript &&
  document.currentScript instanceof HTMLScriptElement
) {
  const currentScriptSrc = document.currentScript.src;
  setScriptSrc(currentScriptSrc);

  const configUrl = getConfigUrl(document.currentScript.src);
  loadConfig(configUrl).then(() => {
    const configState = getState();

    if (isSdkConfigReady(configState)) {
      const { config } = configState;
      mountAnalytics(config.SEGMENT_ID, {
        disable: !config.SEGMENT_ENABLED,
      });

      const documentUrl = new URL(document.URL);

      setCommonProperties({
        sdkVersion: 'v1',
        merchantHost: documentUrl.host,
        sdkSource: currentScriptSrc,
      });

      trackWithCommonProperties('Direct SDK loaded');

      Sentry.init({
        enabled: config.SENTRY_ENABLED,
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
        integrations: [
          Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: [SENTRY_APPLICATION_KEY],
            behaviour: 'drop-error-if-contains-third-party-frames',
          }),
        ],
      });
      Sentry.setContext('integration', {
        sdk_version: 'v1',
        merchant_host: documentUrl.host,
        sdk_source: currentScriptSrc,
      });
    }
  });
}
