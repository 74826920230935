import { getCommonProperties } from './commonProperties';

export const trackWithCommonProperties = (
  event: string,
  additionalProperties?: Record<string, unknown>
) => {
  const commonProperties = getCommonProperties();

  return window.billieSdkAnalytics.track(event, {
    ...commonProperties,
    ...additionalProperties,
  });
};
