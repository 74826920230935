import messenger from '@checkout-ui/shared/cross-document-messenger/messenger';
import { TOPICS } from '@checkout-ui/shared/cross-document-messenger/types';
import { logger } from '@checkout-ui/shared-logger';

import {
  attachModalHandlers,
  iFrameError,
  MODAL_SCENARIOS,
  ModalFrame,
  openModalFrame,
} from '../modalFrame';
import sdkState from '../state';
import { ValidationError } from '../types';
import type { Initialize } from './types';
import { validate } from './validation';

const handleInitializeError = (
  frame: ModalFrame,
  error: ValidationError
): Promise<ValidationError> => {
  const sendInitializeError = () => {
    logger.log('SDK sendInitializeError called with:', error);

    // Send error to modal to be displayed
    messenger.publish(TOPICS.show_error, error);
  };

  return new Promise((resolve) => {
    attachModalHandlers(
      frame,
      {
        onOpen: sendInitializeError,
        onClose: (result) => resolve(result.error as ValidationError),
      },
      MODAL_SCENARIOS.error
    );
  });
};

export const initialize: Initialize = async (options, uiConfig) => {
  const validationError = validate(options);

  if (validationError !== undefined) {
    logger.error('validationError in SDK.Initialize', validationError);

    const showErrorModal = uiConfig?.showErrorModal;
    if (!showErrorModal) {
      return validationError;
    }

    const modalFrame = openModalFrame(uiConfig);
    if (!modalFrame) {
      return iFrameError;
    }

    return handleInitializeError(modalFrame, validationError);
  }

  sdkState.setState({
    ...options,
    uiConfig,
  });

  return true;
};

export default initialize;
