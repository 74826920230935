import { DEFAULT_COUNTRY_LANGUAGE_LOCALE } from '@checkout-ui/shared-context-locale/constants/defaultLocaleValue';
import { getAvailableLocalesList } from '@checkout-ui/shared-context-locale/getAvailableLocalesList';
import { DirectIntegrations } from '@checkout-ui/shared-context-session';
import { CountryType } from '@checkout-ui/shared-domain-entities';
import {
  enums,
  Failure,
  nonempty,
  object,
  optional,
  string,
} from 'superstruct';

import { ErrorType, ValidationError } from '../utils/errors';
import { InitializeOptions } from './types';

const SUPPORTED_PURCHASE_COUNTRIES = [
  CountryType.DE,
  CountryType.NL,
  CountryType.FR,
  CountryType.SE,
];
const DEFAULT_LOCALES = Object.values(
  DEFAULT_COUNTRY_LANGUAGE_LOCALE[CountryType.DE]
);
const ALLOWED_DIRECT_INTEGRATIONS: DirectIntegrations[] = ['direct', 'hpp'];

const createSchema = (purchaseCountry: CountryType) => {
  const supportedLocales = getAvailableLocalesList(
    purchaseCountry,
    DEFAULT_LOCALES
  );

  return object({
    sessionId: nonempty(string()),
    locale: enums(supportedLocales),
    purchaseCountry: enums(SUPPORTED_PURCHASE_COUNTRIES),
    integrationType: optional(enums(ALLOWED_DIRECT_INTEGRATIONS)),
  });
};

const handleValidationError = ({ value, key, type, branch }: Failure) => {
  const option = branch[0];

  const isMissingFieldError = value === undefined;
  const isUnknownFieldError = type === 'never';
  const isLocaleFieldError = option.locale && key === 'locale';
  const isPurchaseCountryFieldError =
    option.purchaseCountry && key === 'purchaseCountry';

  if (isMissingFieldError) {
    return {
      field: key,
      message: 'missing_field',
    };
  } else if (isUnknownFieldError) {
    return {
      field: key,
      message: 'unknown_field',
    };
  } else if (isLocaleFieldError) {
    const message = option.purchaseCountry
      ? `invalid_locale_for_${option.purchaseCountry}`
      : `invalid_locale`;

    return {
      field: key,
      message,
    };
  } else if (isPurchaseCountryFieldError) {
    return {
      field: key,
      message: `invalid_purchase_country_${option.purchaseCountry}`,
    };
  } else {
    return {
      field: key,
      message: 'invalid_field',
    };
  }
};

export const validate = (
  option: InitializeOptions
): ValidationError | undefined => {
  const schema = createSchema(option.purchaseCountry);
  const [error] = schema.validate(option);

  if (!error) {
    return;
  }

  const validationErrors: ValidationError = {
    type: ErrorType.ValidationError,
    errors: error
      .failures()
      .map(handleValidationError) as ValidationError['errors'],
  };

  return validationErrors;
};
