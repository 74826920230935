import { mapModalInternalDataToAuthorizeCall } from '@checkout-ui/shared/data-transformers';
import {
  CountryType,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';

import {
  BillieCheckoutWidget,
  BillieCheckoutWidgetAuthorizedResponse,
  BillieCheckoutWidgetDeclinedResponse,
  BillieCheckoutWidgetDeliveryAddress,
  BillieCheckoutWidgetResponseDebtorCompany,
  BillieCheckoutWidgetResponseDeclineReason,
  BillieCheckoutWidgetResponseState,
} from '../../../types';
import {
  getCommonProperties,
  setCommonProperties,
  trackWithCommonProperties,
} from './analytics';
import { authorize } from './authorize';
import { getState as getSdkConfig } from './config';
import {
  handleErrors,
  handleInitializeErrors,
  mapAuthorizeErrors,
} from './handlers';
import { validateAuthorizationResult } from './helpers';
import { initialize, initializeFingerprint } from './initialize';
import {
  ANONYMIZATION_PRESETS,
  anonymizeData,
  cleanupMountCall,
} from './transform';

const INVOICE_PAYMENT_METHOD = 'invoice';

export const mount: BillieCheckoutWidget['mount'] = async (
  {
    billie_config_data: config,
    billie_order_data: initialOrder,
    billie_widget_options: options,
    ...additionalProperties
  },
  ...additionalArguments
) => {
  const { merchantHost } = getCommonProperties();

  try {
    const anonymizedOrder = anonymizeData(
      initialOrder,
      ANONYMIZATION_PRESETS.ORDER
    );

    Sentry.addBreadcrumb({
      category: 'Integration',
      level: Severity.Info,
      message: 'BillieCheckoutWidget.mount called',
      data: {
        billie_config_data: config,
        billie_order_data: anonymizedOrder,
        billie_widget_options: options,
        additionalProperties,
        additionalArguments,
      },
    });

    const { config: sdkConfig } = getSdkConfig();

    await initializeFingerprint({
      enabled: sdkConfig.FINGERPRINTJS_ENABLED,
      apiKey: sdkConfig.FINGERPRINTJS_KEY,
      sessionId: config.session_id,
    });

    const session = {
      sessionId: config.session_id,
      locale: SupportedLocaleTag.German,
      purchaseCountry: CountryType.DE,
      integrationType: 'direct' as const,
    };

    Sentry.setContext('Session', session);
    setCommonProperties(session);

    trackWithCommonProperties('Direct SDK mount call with arguments', {
      billie_config_data: config,
      billie_order_data: anonymizedOrder,
      billie_widget_options: options,
      additionalProperties,
      additionalArguments,
    });

    const initializeResult = await initialize(session, {
      closeable: true,
      showErrorModal: true,
    });

    const defaultDeclineResponse: BillieCheckoutWidgetDeclinedResponse = {
      state: BillieCheckoutWidgetResponseState.Declined,
      decline_reason: BillieCheckoutWidgetResponseDeclineReason.UnknownError,
      debtor_company: initialOrder.debtor_company,
    };

    if (initializeResult !== true) {
      handleInitializeErrors(initializeResult, defaultDeclineResponse);
    }

    Sentry.setContext('Order', anonymizedOrder);

    const order = cleanupMountCall(initialOrder);

    const [errors, result] = await authorize({
      ...order,
      options: {
        usePublicApiValidationSchema: true,
      },
    });

    if (errors) {
      throw mapAuthorizeErrors(errors, defaultDeclineResponse);
    }

    validateAuthorizationResult(result, initialOrder, defaultDeclineResponse);

    const authorizeData = mapModalInternalDataToAuthorizeCall(result);

    // FIXME: check types properly, there should be no type conversion / inference here
    const mountResolution: BillieCheckoutWidgetAuthorizedResponse = {
      ...authorizeData,
      amount: {
        gross: initialOrder.amount?.gross,
        net: initialOrder.amount?.net,
        tax: initialOrder.amount?.tax,
      },
      duration: initialOrder?.duration,
      line_items: initialOrder?.line_items,
      debtor_company: (authorizeData.debtor_company ||
        initialOrder?.debtor_company) as BillieCheckoutWidgetResponseDebtorCompany,
      delivery_address: ({
        ...authorizeData.delivery_address,
        addition: initialOrder.delivery_address?.addition,
      } ||
        initialOrder?.delivery_address) as BillieCheckoutWidgetDeliveryAddress,
      debtor_company_suggestion: (authorizeData.debtor_company ||
        initialOrder?.debtor_company) as BillieCheckoutWidgetResponseDebtorCompany,
      payment_method: INVOICE_PAYMENT_METHOD,
      state:
        authorizeData.state as BillieCheckoutWidgetAuthorizedResponse['state'],
      decline_reason: null,
      reasons: null,
    };

    const anonymizedResolution = anonymizeData(
      mountResolution,
      ANONYMIZATION_PRESETS.ORDER
    );

    trackWithCommonProperties(
      'Direct SDK mount resolved promise with arguments',
      {
        data: anonymizedResolution,
      }
    );

    return mountResolution;
  } catch (errorResponseObject) {
    return handleErrors(errorResponseObject, merchantHost as string);
  }
};
