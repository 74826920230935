import {
  SdkConfigInitializationStatus,
  SdkConfigReady,
  SdkConfigState,
} from './types';

export const isSdkConfigReady = (
  config: SdkConfigState
): config is SdkConfigReady =>
  config.status === SdkConfigInitializationStatus.Ready;
