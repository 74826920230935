function getType(value: unknown): string {
  if (value === null) return 'null';
  if (Array.isArray(value)) return 'array';
  return typeof value;
}

export const anonymizeData = (data: unknown, paths: string[]) => {
  const anonymizedData = JSON.parse(JSON.stringify(data));

  paths.forEach((path) => {
    const keys = path.split('.');

    const finalObject = keys.slice(0, -1).reduce((memo, key) => {
      return memo ? memo[key] || null : null;
    }, anonymizedData);

    const finalKey = keys.slice(-1)[0];

    if (finalObject && finalKey in finalObject) {
      finalObject[finalKey] = getType(finalObject[finalKey]);
    }
  });

  return anonymizedData;
};

export const ANONYMIZATION_PRESETS = {
  ORDER: [
    'delivery_address.house_number',
    'delivery_address.street',
    'debtor_company.name',
    'debtor_company.address_addition',
    'debtor_company.address_house_number',
    'debtor_company.address_street',
    'debtor_person.salutation',
    'debtor_person.first_name',
    'debtor_person.last_name',
    'debtor_person.phone_number',
    'debtor_person.email',
    'debtor_company_suggestion.address_house_number',
    'debtor_company_suggestion.address_street',
    'debtor_company_suggestion.name',
  ],
};
