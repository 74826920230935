export const joinObjectPath = (path: Array<string | number>): string => {
  return path.reduce<string>((memo, key) => {
    if (typeof key === 'number') {
      return memo + '[' + key.toString(10) + ']';
    }

    if (memo.length === 0) {
      return String(key);
    }

    return memo + '.' + String(key);
  }, '');
};
