import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';

import {
  BillieCheckoutWidgetDeclinedResponse,
  BillieCheckoutWidgetResponseDeclineReason,
  BillieCheckoutWidgetValidationDeclinedResponse,
} from '../../../types';
import { trackWithCommonProperties } from './analytics';
import {
  alertBuyer,
  mapValidationErrorsToLegacyValidationErrorSource,
  transformValidationErrorsToSentryContext,
} from './helpers';
import { ANONYMIZATION_PRESETS, anonymizeData } from './transform';
import { AuthorizeErrors, IFrameError } from './types';
import {
  ErrorType,
  isValidationErrorResponse,
  ValidationError,
} from './utils/errors';

export const handleInitializeErrors = (
  initializeResult: ValidationError | IFrameError,
  defaultDeclineResponse: BillieCheckoutWidgetDeclinedResponse
): never => {
  switch (initializeResult.type) {
    case ErrorType.ValidationError: {
      throw {
        ...defaultDeclineResponse,
        decline_reason:
          BillieCheckoutWidgetResponseDeclineReason.ValidationError,
        validation_error_source:
          mapValidationErrorsToLegacyValidationErrorSource(
            initializeResult.errors
          ),
      };
    }

    case ErrorType.IFrameError: {
      throw {
        ...defaultDeclineResponse,
        decline_reason:
          BillieCheckoutWidgetResponseDeclineReason.SessionRejected,
        validation_error_source:
          mapValidationErrorsToLegacyValidationErrorSource(
            initializeResult.errors
          ),
      };
    }
    default: {
      console.error('Unhandled initialize error', initializeResult);
      throw defaultDeclineResponse;
    }
  }
};

export const handleErrors = (
  errorResponseObject: unknown,
  merchantHost: string
): never => {
  if (errorResponseObject instanceof Error) {
    Sentry.captureException(errorResponseObject, { level: Severity.Fatal });
    trackWithCommonProperties(
      'Direct SDK mount rejected promise with arguments',
      { handled: false, unhandledError: errorResponseObject.toString() }
    );
  } else if (isValidationErrorResponse(errorResponseObject)) {
    Sentry.captureException(
      new Error(`Integration validation error for shop at "${merchantHost}"`),
      (scope) => {
        scope.setLevel(Severity.Warning);
        scope.setContext(
          'Catch Response',
          errorResponseObject as BillieCheckoutWidgetValidationDeclinedResponse
        );
        return scope;
      }
    );
  } else {
    Sentry.captureException(
      new Error(`Authorization declined for shop at "${merchantHost}"`),
      (scope) => {
        scope.setLevel(Severity.Info);
        scope.setContext(
          'Catch Response',
          errorResponseObject as Record<string, unknown>
        );
        return scope;
      }
    );
  }

  const errorResponse =
    errorResponseObject as BillieCheckoutWidgetDeclinedResponse;
  const anonymizedErrorResponse = anonymizeData(
    errorResponse,
    ANONYMIZATION_PRESETS.ORDER
  );

  trackWithCommonProperties(
    'Direct SDK mount rejected promise with arguments',
    { handled: true, error: anonymizedErrorResponse }
  );

  throw errorResponseObject;
};

export const mapAuthorizeErrors = (
  errors: AuthorizeErrors,
  defaultDeclineResponse: BillieCheckoutWidgetDeclinedResponse
) => {
  alertBuyer();
  if (errors.type === ErrorType.ValidationError) {
    Sentry.addBreadcrumb({
      category: 'Validation Errors',
      level: Severity.Warning,
      message: 'Integration validation errors captured',
      data: transformValidationErrorsToSentryContext(errors.errors),
    });

    return {
      ...defaultDeclineResponse,
      decline_reason: BillieCheckoutWidgetResponseDeclineReason.ValidationError,
      validation_error_source: mapValidationErrorsToLegacyValidationErrorSource(
        errors.errors
      ),
    };
  }

  if (errors.type === ErrorType.AuthorizeError) {
    return {
      ...defaultDeclineResponse,
      decline_reason: BillieCheckoutWidgetResponseDeclineReason.RiskPolicy,
    };
  }

  console.error('Unhandled authorize error', errors);
  return defaultDeclineResponse;
};
