export type SdkConfig = {
  DIRECT_MODAL_SRC: string;
  SEGMENT_ENABLED: boolean;
  SEGMENT_ID: string;
  SENTRY_ENABLED: boolean;
  SENTRY_DSN: string;
  SENTRY_ENVIRONMENT: string;
  FINGERPRINTJS_ENABLED: boolean;
  FINGERPRINTJS_KEY: string;
};

export enum SdkConfigInitializationStatus {
  Initial,
  Loading,
  Ready,
  Error,
}

export type SdkConfigInitial = {
  status: SdkConfigInitializationStatus.Initial;
  config: Record<string, never>;
};

export type SdkConfigLoading = {
  status: SdkConfigInitializationStatus.Loading;
  configSrc: string;
  config: Record<string, never>;
};

export type SdkConfigReady = {
  status: SdkConfigInitializationStatus.Ready;
  configSrc: string;
  config: SdkConfig;
};

export type SdkConfigError = {
  status: SdkConfigInitializationStatus.Error;
  configSrc: string;
  config: Record<string, never>;
  error: Error;
};

export type SdkConfigState =
  | SdkConfigInitial
  | SdkConfigLoading
  | SdkConfigReady
  | SdkConfigError;

export type SdkConfigInitializationReadyCallback = (
  config: SdkConfigReady
) => unknown;
export type SdkConfigInitializationErrorCallback = (
  config: SdkConfigError
) => unknown;

export type SdkConfigInitializationCallbacks = {
  ready: Set<SdkConfigInitializationReadyCallback>;
  error: Set<SdkConfigInitializationErrorCallback>;
};
