import { SdkConfig } from './types';

type RawSdkConfig = {
  [key in keyof SdkConfig]: string;
};

export const parseBoolean = (value: string): boolean =>
  ['true', '1'].includes(value.toLowerCase());

export const parse = (rawConfig: RawSdkConfig): SdkConfig => {
  return {
    ...rawConfig,
    SEGMENT_ENABLED: parseBoolean(rawConfig.SEGMENT_ENABLED),
    SENTRY_ENABLED: parseBoolean(rawConfig.SENTRY_ENABLED),
    FINGERPRINTJS_ENABLED: parseBoolean(rawConfig.FINGERPRINTJS_ENABLED),
  };
};
