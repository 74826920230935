import { CountryType } from '@checkout-ui/shared-domain-entities';

import { InitializeOptions } from './initialize';
import { UiConfig } from './types';

export type SDKState = InitializeOptions & {
  uiConfig?: UiConfig;
};

const safeCloneState = (state: SDKState) => {
  try {
    const clonedState: SDKState = JSON.parse(
      JSON.stringify({
        ...state,
      })
    );

    return clonedState;
  } catch (_) {
    return {
      ...state,
    };
  }
};

const defaultSdkState: SDKState = {
  sessionId: '',
  locale: 'de', //default value
  purchaseCountry: CountryType.DE,
  integrationType: 'direct', //default value
  uiConfig: {
    container: '',
    closeable: true,
  },
};

class SdkState {
  private state = defaultSdkState;

  setState = (nextState: SDKState) => {
    this.state = safeCloneState({
      ...this.state,
      ...nextState,
      uiConfig: {
        ...this.state.uiConfig,
        ...nextState.uiConfig,
      },
    });
  };

  getState = () => {
    return Object.freeze(safeCloneState({ ...this.state }));
  };
}

const sdkState = new SdkState();

export default sdkState;

const script = {
  src: '',
};

export const setScriptSrc = (src: string) => {
  script.src = src;
};

export const getScriptSrc = () => {
  return script.src;
};
