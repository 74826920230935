export type __Billie__ = {
  log_level: 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'none';
};

declare global {
  interface Window {
    __Billie__: __Billie__;
  }
}

export enum LOG_METHODS {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export interface Options {
  name: string;
  color: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LoggerParams = any[]
