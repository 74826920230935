export type BillieCheckoutWidgetConfig = {
  session_id: string;
  merchant_name?: string | null;
};

export type BillieCheckoutWidgetAmount = {
  gross: number;
  tax: number;
  net: number;
};

type BillieCheckoutWidgetOrderItem = {
  external_id?: string | null;
  title: string;
  description?: string | null;
  quantity: number;
  category?: string | null;
  brand?: string | null;
  gtin?: string | null;
  mpn?: string | null;
  amount: BillieCheckoutWidgetAmount;
  [key: string]: unknown;
};

export type BillieCheckoutWidgetDeliveryAddress = {
  street: string;
  house_number?: string | null;
  addition?: string | null;
  city: string;
  postal_code: string;
  country: string;
  // we allow additional properties to be passed in the mount call for compatibility reasons
  // these properties are not used, see also: https://ozean12.atlassian.net/browse/PURCHASE-1148
  [key: string]: unknown;
};

export type BillieCheckoutWidgetOrder = {
  amount: BillieCheckoutWidgetAmount;
  duration: number;
  comment?: string | null;
  delivery_address?: BillieCheckoutWidgetDeliveryAddress;
  debtor_company: {
    name: string;
    established_customer?: boolean | null;
    address_street: string;
    address_house_number?: string | null;
    address_addition?: string | null;
    address_city: string;
    address_postal_code: string;
    address_country: string;
    legal_form?: string | null;
    // we allow additional properties to be passed in the mount call for compatibility reasons
    // these properties are not used, see also: https://ozean12.atlassian.net/browse/PURCHASE-1148
    [key: string]: unknown;
  };
  debtor_person?: {
    salutation?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    phone_number?: string | null;
    email?: string | null;
    // we allow additional properties to be passed in the mount call for compatibility reasons
    // these properties are not used, see also: https://ozean12.atlassian.net/browse/PURCHASE-1148
    [key: string]: unknown;
  };
  line_items?: BillieCheckoutWidgetOrderItem[];
  // we allow additional properties to be passed in the mount call for compatibility reasons
  // these properties are not used, see also: https://ozean12.atlassian.net/browse/PURCHASE-1148
  [key: string]: unknown;
};

export enum BillieCheckoutWidgetPaymentMethod {
  Invoice = 'invoice',
  DirectDebit = 'direct_debit',
}

type BillieCheckoutWidgetOptions = {
  payment_method: BillieCheckoutWidgetPaymentMethod;
};

type BillieCheckoutWidgetResponse =
  | BillieCheckoutWidgetAuthorizedResponse
  | BillieCheckoutWidgetPreWaitingResponse
  | BillieCheckoutWidgetDeclinedResponse;

export enum BillieCheckoutWidgetResponseState {
  Authorized = 'authorized',
  PreWaiting = 'pre_waiting',
  Declined = 'declined',
}

export enum BillieCheckoutWidgetResponseDeclineReason {
  SessionRejected = 'session_rejected',
  ValidationError = 'validation_error',
  RiskPolicy = 'risk_policy',
  DebtorNotIdentified = 'debtor_not_identified',
  DebtorAddress = 'debtor_address',
  RiskScoringFailed = 'risk_scoring_failed',
  DebtorLimitExceeded = 'debtor_limit_exceeded',
  UnknownError = 'unknown_error',
}

export type BillieCheckoutWidgetResponseDebtorCompany = {
  name: string;
  address_street: string;
  address_house_number?: string | null;
  address_city: string;
  address_postal_code: string;
  address_country: string;
};

export type BillieCheckoutWidgetAuthorizedResponse = {
  state: BillieCheckoutWidgetResponseState.Authorized;
  debtor_company: BillieCheckoutWidgetResponseDebtorCompany;
  amount: BillieCheckoutWidgetAmount;
  duration: number;
  delivery_address?: BillieCheckoutWidgetDeliveryAddress;
  line_items?: BillieCheckoutWidgetOrderItem[];
  // FIXME: deprecated, schedule for removal
  debtor_company_suggestion?: BillieCheckoutWidgetResponseDebtorCompany;
  payment_method: 'invoice';
  // FIXME: deprecated, schedule for removal
  decline_reason: null;
  // FIXME: deprecated, schedule for removal
  reasons: null;
};

export type BillieCheckoutWidgetPreWaitingResponse = {
  state: BillieCheckoutWidgetResponseState.PreWaiting;
  debtor_company: BillieCheckoutWidgetResponseDebtorCompany;
};

export type BillieCheckoutWidgetValidationDeclinedResponse = {
  state: BillieCheckoutWidgetResponseState.Declined;
  decline_reason: BillieCheckoutWidgetResponseDeclineReason.ValidationError;
  validation_error_source: string;
  debtor_company: BillieCheckoutWidgetResponseDebtorCompany;
};

export type BillieCheckoutWidgetDeclinedResponse =
  | {
      state: BillieCheckoutWidgetResponseState.Declined;
      decline_reason: BillieCheckoutWidgetResponseDeclineReason;
      validation_error_source?: string;
      debtor_company: BillieCheckoutWidgetResponseDebtorCompany;
    }
  | BillieCheckoutWidgetValidationDeclinedResponse;

export type BillieCheckoutWidget = {
  mount: (payload: {
    billie_config_data: BillieCheckoutWidgetConfig;
    billie_order_data: BillieCheckoutWidgetOrder;
    billie_widget_options?: BillieCheckoutWidgetOptions;
  }) => Promise<BillieCheckoutWidgetResponse>;
};
