/**
 * The string part of the Environment enum should not be used for matching the environment.
 * Consider it more a reference to the environment(s) it corresponds to.
 */
export enum Environment {
  Local = 'localhost',
  TestInstance = '*.test*.ozean12.com',
  Sandbox = 'paella-sandbox.billie.io',
  Production = 'static.billie.io',
}
