type PossibleRecursiveRemoveNullsInputs =
  | string
  | number
  | boolean
  | Record<string, unknown>
  | Array<unknown>
  | undefined
  | null;

type PossibleRecursiveRemoveNullsOutputs<In> = In extends undefined | null
  ? undefined
  : In extends string | number | boolean
  ? In
  : In extends Array<infer U>
  ? Array<PossibleRecursiveRemoveNullsOutputs<U>>
  : In extends Record<string, unknown>
  ? {
      [K in keyof In]?: PossibleRecursiveRemoveNullsOutputs<In[K]>;
    }
  : undefined;

const makeCopyThroughJsonSerialization = <
  T extends PossibleRecursiveRemoveNullsInputs,
  R = PossibleRecursiveRemoveNullsOutputs<T>
>(
  item: T
): R => JSON.parse(JSON.stringify(item));

export const recursiveRemoveNulls = <
  T extends PossibleRecursiveRemoveNullsInputs,
  R = PossibleRecursiveRemoveNullsOutputs<T>
>(
  obj: T
): R => {
  if (obj === undefined) {
    return undefined as R;
  }

  if (obj === null) {
    return undefined as R;
  }

  if (Array.isArray(obj)) {
    return makeCopyThroughJsonSerialization(
      obj.map((item) => recursiveRemoveNulls(item))
    );
  }

  if (typeof obj === 'object') {
    const keys = Object.keys(obj);
    const result: Partial<typeof obj> = {};

    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = recursiveRemoveNulls(obj[key]);
    });

    return makeCopyThroughJsonSerialization(result);
  }

  return makeCopyThroughJsonSerialization(obj);
};
