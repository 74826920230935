const commonProperties = {
  state: {},
};

export const setCommonProperties = (props: Record<string, unknown>) => {
  commonProperties.state = {
    ...commonProperties.state,
    ...props,
  };
};

export const getCommonProperties = (): Record<string, unknown> =>
  commonProperties.state;
