import { isDevEnv } from '@checkout-ui/shared/utils';
import { logger } from '@checkout-ui/shared-logger';

type Assert = (expression: boolean, errorMessage: string) => void;

export const assert: Assert = (expression, errorMessage) => {
  const isExpressionTruthy = !!expression;

  if (isExpressionTruthy) {
    return;
  }

  if (isDevEnv()) {
    throw new Error(errorMessage);
  } else {
    logger.error(errorMessage);
  }
};
