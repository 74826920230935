import {
  AllDeclinedReasons,
  AuthorizationResults,
  CountryType,
  ModalData,
  Session,
} from '@checkout-ui/shared-domain-entities';

import {
  AuthorizationData,
  AuthorizationModalData,
  direct_auth_request,
  HppSessionCallResponse,
} from './authorizationDataTypes';

//FIXME: this is not the session context use in the session creation response
// should have session_id instead of token
const session_context: Session = {
  country: CountryType.DE,
  currency: 'EUR',
  locale: 'en-US',
  token: 'token',
  channel: 'ecommerce',
  integrationType: 'hpp', //Fixme: this fixture can be used by other integrations
  autoconfirm: false,
} as const;

const delivery_address = {
  house_number: '4',
  street: 'Charlottenstr.',
  city: 'Berlin',
  postal_code: '10969',
  country: 'DE',
};
const shipping_address = {
  street: 'Charlottenstr.',
  number: '4',
  postal_code: '10969',
  city: 'Berlin',
  country: 'DE',
};
const debtor_address = {
  street: 'Charlottenstr.',
  number: '4',
  postal_code: '10969',
  city: 'Berlin',
  country: 'DE',
  address_addition: 'some addition',
};
const debtor_company = {
  legal_form: 'limited_company',
  name: 'Billie GmbH',
  address_addition: 'some addition',
  address_house_number: '4',
  address_street: 'Charlottenstr.',
  address_city: 'Berlin',
  address_postal_code: '10969',
  address_country: 'DE',
};

// buyer values
const debtor_person = {
  salutation: 'm',
  first_name: 'John',
  last_name: 'Doe',
  phone_number: '491522530345',
  email: 'test-qa@billie.io',
};
const contact = {
  salutation: debtor_person.salutation,
  given_name: debtor_person.first_name,
  family_name: debtor_person.last_name,
  phone: debtor_person.phone_number,
  email: debtor_person.email,
};

//suggestion values
const debtor_company_suggestion = {
  legal_form: 'limited_company',
  name: 'suggested company',
  address_addition: '',
  address_house_number: '420',
  address_street: 'suggested str.',
  address_city: 'Berlin',
  address_postal_code: '10969',
  address_country: 'DE',
};
const suggested_debtor = {
  name: 'suggested company',
  legal_form: 'limited_company',
  address: {
    street: 'suggested str.',
    number: '420',
    postal_code: '10969',
    city: 'Berlin',
    country: 'DE',
  },
  contact,
};

//purchase values
const lineItem = {
  external_id: 'SKU-001',
  title: 'Generic item',
  description: 'Generic item test purchase',
  quantity: 1,
  category: 'Generic',
  brand: 'Generic',
  gtin: 'Test gtin',
  mpn: 'Test mpn',
  amount: { gross: 100, tax: 19, net: 81 },
  product_url: '',
  image_url: '',
  type: '',
  quantity_unit: '',
  tax_rate: 0,
  total_discount_amount: 0,
  unit_price: 0,
};
const amount = { gross: 100, tax: 19, net: 81 };
const duration = 30;
const comment = 'some comment';
const order_id = 'test-external-order-id123';

const decline_reason = AllDeclinedReasons.DEBTOR_LIMIT_EXCEEDED;
const reasons = AllDeclinedReasons.DEBTOR_LIMIT_EXCEEDED;
const state = 'declined'; //auth
const result = AuthorizationResults.REJECTED; //modal

const modalDebtorCompany = {
  name: 'Billie GmbH',
  legal_form: 'limited_company',
  contact,
  address: debtor_address,
};

const modalHppSessionContext = {
  country: CountryType.DE,
  currency: 'EUR',
  locale: 'en-US',
  token: 'token',
  channel: 'ecommerce',
  integrationType: 'hpp',
  autoconfirm: false,
} as const;

// response or init from SDK
export const mockDirectAuthorizationData: AuthorizationData = {
  //pass from SDK
  session_context,

  //passed on auth response
  decline_reason,
  reasons,
  state,
  debtor_company_suggestion,

  //always passed
  amount,
  comment,
  order_id,
  duration,
  delivery_address,
  debtor_company,
  debtor_person,
  line_items: [lineItem],
};

const modalPaymentDetails = {
  payment_deadline: duration,
};

const modalPurchaseDetails = {
  comment,
  order_id,
  amount: {
    // https://ozean12.atlassian.net/browse/CHECK-530
    total: amount.gross * 100,
    tax: amount.tax * 100,
    net: amount.net * 100,
  },

  line_items: [lineItem],
  shipping_address,
};

// auth response transformed to modal compatible
export const mockAuthorizationModalData: AuthorizationModalData = {
  authorization_evaluation: {
    result,
    decline_reason,
    suggested_debtor,
  },
  session_context,
  payment_details: modalPaymentDetails,
  purchase_details: modalPurchaseDetails,
  debtor_company: modalDebtorCompany,
};

// modal data transformed to auth request
export const mockAuthRequestData: direct_auth_request = {
  amount,
  comment,
  order_id,
  duration,
  delivery_address,
  debtor_company,
  debtor_person,
  line_items: [lineItem],
};

export const mockHppSessionResponse: HppSessionCallResponse = {
  shop_name: 'e2e test shop',
  merchant_legal_name: 'Billie',
  session_context: {
    session_id: 'token',
    country: CountryType.DE,
    currency: 'EUR',
    locale: 'en-US',
    expires_at: new Date('2060-09-27T08:33:40+00:00'),
    channel: 'ecommerce',
    autoconfirm: false,
  },
  merchant_urls: {
    accept_url: 'http://localhost:4205/success',
    decline_url: 'http://localhost:4205/failure',
  },

  amount,
  comment,
  order_id,
  duration,
  debtor_person,
  debtor_company,
  delivery_address,
  line_items: [lineItem],
};

export const mockHppSessionModalData: Omit<
  ModalData,
  'authorization_evaluation'
> = {
  debtor_company: modalDebtorCompany,
  payment_details: modalPaymentDetails,
  purchase_details: modalPurchaseDetails,
  session_context: modalHppSessionContext,
};
