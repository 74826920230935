import {
  BillieCheckoutWidgetResponseDeclineReason,
  BillieCheckoutWidgetResponseState,
  BillieCheckoutWidgetValidationDeclinedResponse,
} from '../../../../../types';

export const isValidationErrorResponse = (
  rs: unknown
): rs is BillieCheckoutWidgetValidationDeclinedResponse => {
  if (!rs) {
    return false;
  }

  if (typeof rs !== 'object') {
    return false;
  }

  if (
    !('state' in rs) ||
    !('decline_reason' in rs) ||
    !('validation_error_source' in rs)
  ) {
    return false;
  }

  if (
    rs.state !== BillieCheckoutWidgetResponseState.Declined ||
    rs.decline_reason !==
      BillieCheckoutWidgetResponseDeclineReason.ValidationError ||
    typeof rs.validation_error_source !== 'string'
  ) {
    return false;
  }

  return true;
};
