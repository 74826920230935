export enum ErrorType {
  ValidationError = 'validation_error',
  AuthorizeError = 'authorize_error',
  AxiosFormattedError = 'axios_error',
  IFrameError = 'iframe_error',
}

export type ErrorField = { field: string; message: string };

export type ValidationErrorField = { code: string; source: string };

export type ValidationError = {
  type: ErrorType.ValidationError;
  errors: Array<ErrorField>;
};

export type IFrameError = {
  type: ErrorType.IFrameError;
  errors: Array<ErrorField>;
};

export type AuthorizeResultError = {
  type: ErrorType.AuthorizeError;
  messages: string[];
};

export enum SupportedCodes {
  ValidationError = 400,
  SessionRejected = 401,
  RuntimeException = 500,
}

export enum SupportedErrors {
  ValidationError = 'validation_error',
  SessionRejected = 'session_rejected',
  UnknownError = 'unknown_error',
  DeclineReason = 'decline_reason',
  //FIXME: temporary solution till we have unified structure for errors
  IframeValidationError = 'iframe_validation_error',
}

export type AxiosFormattedError = {
  type: ErrorType.AxiosFormattedError;
  status: SupportedCodes;
  errors: Array<ValidationErrorField>;
};
