import { CountryType } from '@checkout-ui/shared-domain-entities';

import { Intent, PreDefinedScenario } from './types';

export const SANDBOX_IFRAME_ID = 'billie-sandbox';
export const DEMO_SHOP_ID = 'demo-shop';

//FIXME: move USER_SCENARIOS and preDefinedScenarios to a dedicated lib (not relevant to crossDocumentMessenger)
export const USER_SCENARIOS = {
  confirmation: 'demo@billie.io',
  companySuggestion: 'demo+decline+debtor_identified_strict@billie.io',
  identificationFailure: 'demo+decline+debtor_identified@billie.io',
  authorizationFailure: 'demo+decline+debtor_not_customer@billie.io',
  limitExceeded: 'demo+decline+limit@billie.io',
  custom: 'demo@billie.io',
};

export const SCENARIOS_AVAILABLE: Record<Intent, PreDefinedScenario> = {
  confirmation: {
    translationKey: 'demo-shop.configuration-flows.authorization',
    intent: 'confirmation',
  },
  companySuggestion: {
    translationKey: 'demo-shop.configuration-flows.company-suggestion',
    intent: 'companySuggestion',
  },
  identificationFailure: {
    translationKey: 'demo-shop.configuration-flows.re-identification',
    intent: 'identificationFailure',
  },
  authorizationFailure: {
    translationKey: 'demo-shop.configuration-flows.auth.failed',
    intent: 'authorizationFailure',
  },
  limitExceeded: {
    translationKey: 'demo-shop.configuration-flows.payment.limit.exceeded',
    intent: 'limitExceeded',
  },
  custom: {
    translationKey: 'demo-shop.configuration-flows.edit.input',
    intent: 'custom',
  },
};

export const COUNTRIES_SCENARIO_LISTS:
  { __DEFAULT__: PreDefinedScenario[] }
  & Partial<Record<CountryType, PreDefinedScenario[]>> = {
  __DEFAULT__: [
    SCENARIOS_AVAILABLE.confirmation,
    SCENARIOS_AVAILABLE.companySuggestion,
    SCENARIOS_AVAILABLE.identificationFailure,
    SCENARIOS_AVAILABLE.authorizationFailure,
    SCENARIOS_AVAILABLE.limitExceeded,
    SCENARIOS_AVAILABLE.custom,
  ],
  SE: [
    SCENARIOS_AVAILABLE.confirmation,
    SCENARIOS_AVAILABLE.identificationFailure,
    SCENARIOS_AVAILABLE.authorizationFailure,
    SCENARIOS_AVAILABLE.limitExceeded,
    SCENARIOS_AVAILABLE.custom,
  ],
  FR: [
    SCENARIOS_AVAILABLE.confirmation,
    SCENARIOS_AVAILABLE.identificationFailure,
    SCENARIOS_AVAILABLE.authorizationFailure,
    SCENARIOS_AVAILABLE.limitExceeded,
    SCENARIOS_AVAILABLE.custom,
  ],
  NL: [
    SCENARIOS_AVAILABLE.confirmation,
    SCENARIOS_AVAILABLE.identificationFailure,
    SCENARIOS_AVAILABLE.authorizationFailure,
    SCENARIOS_AVAILABLE.limitExceeded,
    SCENARIOS_AVAILABLE.custom,
  ],
};

export const FRAME_SUBSCRIBED_EVENT = 'frame_subscribed';
