import { AuthorizationModalData } from '@checkout-ui/shared/data-transformers';

import {
  BillieCheckoutWidgetDeclinedResponse,
  BillieCheckoutWidgetOrder,
  BillieCheckoutWidgetResponseDeclineReason,
} from '../../../types';
import {
  AxiosFormattedError,
  ErrorField,
  ErrorType,
  SESSION_VALIDATION_ERROR,
  SupportedCodes,
  SupportedErrors,
  ValidationError,
} from './utils/errors';

export const alertBuyer = (): void => {
  const alertMessage = [
    'Autorisierung fehlgeschlagen',
    '',
    'Leider ist unsere Autorisierung fehlgeschlagen. Daher können wir Ihnen die Zahlungsmethode Rechnungskauf derzeit nicht anbieten.',
    '',
    'Was können Sie tun?',
    '',
    ' • Stellen Sie sicher, dass Ihre Angaben korrekt sind, damit wir das richtige Unternehmen identifizieren können.',
    ' • Sollte das Problem weiterhin bestehen, wählen Sie bitte eine andere Zahlungsmethode.',
  ].join('\n');
  alert(alertMessage);
};

const isAxiosFormattedError = (
  error: unknown
): error is AxiosFormattedError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'type' in error &&
    'status' in error &&
    (error as AxiosFormattedError).type === ErrorType.AxiosFormattedError
  );
};

const isValidationError = (error: unknown): error is ValidationError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'type' in error &&
    (error as ValidationError).type === ErrorType.ValidationError
  );
};

export const mapValidationErrorsToLegacyValidationErrorSource = (
  errors?: ErrorField[]
): string => {
  if (!errors || errors.length === 0) {
    return 'unknown error';
  }

  const sessionError = errors.find((error) => error.field === 'sessionId');
  return sessionError
    ? 'invalid session_id'
    : errors[0]?.field ?? 'unknown error';
};

export const transformValidationErrorsToSentryContext = (
  errors: ErrorField[]
): Record<string, string> =>
  errors.reduce<Record<string, string>>((memo, error) => {
    if (!error?.field || !error?.message) return memo;
    memo[error.field] = memo[error.field]
      ? `${memo[error.field]}, ${error.message}`
      : error.message;
    return memo;
  }, {});

export const mapResponseToErrorWithType = (
  result: AuthorizationModalData | undefined
): SupportedErrors | null => {
  const error = result?.error;
  const authorization_evaluation = result?.authorization_evaluation;

  const isAuthorizationDeclined =
    authorization_evaluation && authorization_evaluation.result !== 'accepted';

  if (!error && isAuthorizationDeclined) {
    return SupportedErrors.DeclineReason;
  }

  if (error && isAxiosFormattedError(error)) {
    const { status } = error as AxiosFormattedError;
    const errorType =
      status === SupportedCodes.ValidationError
        ? SupportedErrors.ValidationError
        : status === SupportedCodes.SessionRejected
        ? SupportedErrors.SessionRejected
        : SupportedErrors.UnknownError;
    return errorType;
  }

  if (error && isValidationError(error)) {
    //FIXME: temporary solution till we have unified structure for errors
    return SupportedErrors.IframeValidationError;
  }

  if (error) {
    return SupportedErrors.UnknownError;
  }

  if (!authorization_evaluation) {
    return SupportedErrors.UnknownError;
  }

  return null;
};

export const validateAuthorizationResult = (
  result: AuthorizationModalData | undefined,
  initialOrder: BillieCheckoutWidgetOrder,
  defaultDeclineResponse: BillieCheckoutWidgetDeclinedResponse
) => {
  const errorType = mapResponseToErrorWithType(result);
  if (!errorType) return;

  switch (errorType) {
    case SupportedErrors.DeclineReason: {
      const decline_reason =
        result?.authorization_evaluation?.decline_reason ||
        defaultDeclineResponse.decline_reason;

      throw {
        ...defaultDeclineResponse,
        decline_reason,
        ...initialOrder,
      };
    }

    case SupportedErrors.ValidationError: {
      const { errors = [] } = result?.error as AxiosFormattedError;
      const [{ source }] = errors;
      throw {
        ...defaultDeclineResponse,
        decline_reason:
          BillieCheckoutWidgetResponseDeclineReason.ValidationError,
        validation_error_source: `billie_order_data.${source}`,
        ...initialOrder,
      };
    }

    case SupportedErrors.SessionRejected: {
      throw {
        ...defaultDeclineResponse,
        decline_reason:
          BillieCheckoutWidgetResponseDeclineReason.SessionRejected,
        validation_error_source: SESSION_VALIDATION_ERROR,
        ...initialOrder,
      };
    }

    //FIXME: temporary solution till we have unified structure for errors
    case SupportedErrors.IframeValidationError: {
      const { errors = [] } = result?.error as ValidationError;
      const [{ field }] = errors;
      throw {
        ...defaultDeclineResponse,
        decline_reason:
          BillieCheckoutWidgetResponseDeclineReason.ValidationError,
        validation_error_source: `billie_order_data.${field}`,
        ...initialOrder,
      };
    }

    case SupportedErrors.UnknownError: {
      throw defaultDeclineResponse;
    }
  }
};
