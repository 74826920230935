// inspired by https://github.com/rautio/micro-frontend-demo/blob/main/main/src/services/pubsub.ts
import { logger } from '@checkout-ui/shared-logger';
import FrameBus from 'framebus';
import { FramebusOnHandler } from 'framebus/dist/lib/types';
import { v4 as uuid } from 'uuid';

import { FRAME_SUBSCRIBED_EVENT } from './constants';
import {
  Config,
  CrossDocumentMessengerInterface,
  Message,
  OnMessageFn,
  Topic,
} from './types';
import { assert } from './utils';

class MessengerNotReadyError extends Error {
  constructor() {
    super();
    this.message =
      'Cross Document Messenger is not ready to perform operations';
  }
}

export class CrossDocumentMessenger implements CrossDocumentMessengerInterface {
  constructor() {
    this.subscribe.bind(this);
    this.publish.bind(this);
  }

  init({ persistedTopics, ...frameBusOptions }: Config = {}) {
    let isReady = false;

    this.bus = new FrameBus(frameBusOptions);
    this._id = uuid();

    if (persistedTopics) {
      assert(
        Array.isArray(persistedTopics),
        'Persisted topics must be an array of topics.'
      );
      try {
        this.persistedMessages = persistedTopics.reduce(
          (acc: Record<Topic, Message>, cur: Topic) => {
            acc[cur] = {};
            return acc;
          },
          {}
        );

        // attach a listener to replay messages when a frame subscribes to topic
        persistedTopics.forEach((topic) => {
          this.bus?.on(
            `${FRAME_SUBSCRIBED_EVENT}:${topic}`,
            this.publishPersistedMessages
          );
        });
      } catch (error) {
        logger.error(error);
      }
    }

    isReady = true;

    return isReady;
  }

  // Keep track of messages that are persisted and sent to new subscribers
  private persistedMessages: Record<Topic, Message> = {};
  protected bus: FrameBus | undefined;
  private _id: string | undefined;

  /**
   * Subscribe to messages being published in the given topic.
   * @param topic Name of the topic where messages are published.
   * @param onMessage Function called whenever new messages on the topic are published.
   */
  public subscribe<T extends Message>(topic: Topic, onMessage: OnMessageFn<T>) {
    if (!this.bus) {
      throw new MessengerNotReadyError();
    }

    // Validate inputs
    assert(typeof topic === 'string', 'Topic must be a string.');
    assert(typeof onMessage === 'function', 'onMessage must be a function.');

    //delegate the functionality to framebus
    this.bus.on(topic, onMessage as FramebusOnHandler);

    // announce new frame subscribed for topic
    // this event will be picked up by parents and persisted messages will be replayed
    this.bus.emit(`${FRAME_SUBSCRIBED_EVENT}:${topic}`, {
      topic,
      id: this._id,
    });
  }

  /**
   * Unsubscribe from a given topic.
   * @param topic Name of the topic to unsubscribe from
   * @param onMessage the Function that is attached to the topic listener
   */
  public unsubscribe<T extends Message>(
    topic: Topic,
    onMessage: OnMessageFn<T>
  ): void {
    if (!this.bus) {
      throw new MessengerNotReadyError();
    }

    // Validate inputs
    assert(typeof topic === 'string', 'Topic must be a string.');
    assert(typeof onMessage === 'function', 'onMessage must be a function.');

    // delegate the functionality to framebus
    this.bus.off(topic, onMessage as FramebusOnHandler);
  }

  private publishPersistedMessages = (msg: Message) => {
    const topic = msg['topic'] as Topic;

    if (!this.persistedMessages) {
      return;
    }

    const persistedMessageForTopic = this.persistedMessages[topic];

    if (persistedMessageForTopic) {
      this.publish(topic, persistedMessageForTopic);
    }
  };

  /**
   * Publish messages on a topic for all subscribers to receive.
   * @param topic The topic where the message is sent.
   * @param message The message to send. Only object format is supported.
   */
  public publish(topic: Topic, message?: Message) {
    if (!this.bus) {
      throw new MessengerNotReadyError();
    }

    // Validate inputs
    assert(typeof topic === 'string', 'Topic must be a string.');
    assert(typeof message === 'object', 'Message must be an object.');

    // only persist if needed
    const isTopicPersisted = this.persistedMessages[topic] !== undefined;
    if (isTopicPersisted) {
      this.persistedMessages[topic] = message || {};
    }

    // delegate the functionality to framebus
    this.bus.emit(topic, message);
  }

  /**
   * Destroy the messenger and remove all listener.
   */
  public destroy() {
    if (!this.bus) {
      throw new MessengerNotReadyError();
    }

    // reset persisted messages
    this.persistedMessages = {};

    //destroy framebus listeners
    this.bus.teardown();
  }
}

export default new CrossDocumentMessenger();
