import messenger from '@checkout-ui/shared/cross-document-messenger/messenger';
import { TOPICS } from '@checkout-ui/shared/cross-document-messenger/types';
import { DirectHppModalSession } from '@checkout-ui/shared-context-session';
import { isHppEcommerceSession } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';

import {
  attachModalHandlers,
  iFrameError,
  MODAL_SCENARIOS,
  ModalFrame,
  openModalFrame,
} from '../modalFrame';
import sdkState from '../state';
import {
  AuthorizeCallback,
  AuthorizeData,
  AuthorizeDataOrCallback,
  AuthorizeErrors,
  AuthorizeParams,
  AuthorizePromise,
} from './types';
import { validateParameters } from './validation';

const isCallback = (callback: unknown): callback is AuthorizeCallback =>
  typeof callback === 'function';

const handleErrorWithoutFrame = (
  error?: AuthorizeErrors,
  callback?: AuthorizeCallback
) => {
  if (isCallback(callback)) {
    return callback(error, undefined);
  }
  return Promise.resolve([error, undefined]);
};

const handleAuthorizeError = (
  frame: ModalFrame,
  error?: AuthorizeErrors,
  callback?: AuthorizeCallback
) => {
  const sendErrorData = () => {
    logger.log('SDK handleAuthorizeError called with:', error);

    // Forward the session context from the sdk.
    messenger.publish(TOPICS.show_error, error);
  };

  if (callback && isCallback(callback)) {
    return attachModalHandlers(
      frame,
      {
        onOpen: sendErrorData,
        onClose: (result) => callback(undefined, result),
      },
      MODAL_SCENARIOS.error
    );
  }

  return new Promise((resolve) => {
    attachModalHandlers(
      frame,
      {
        onOpen: sendErrorData,
        onClose: (result) => resolve([undefined, result]),
      },
      MODAL_SCENARIOS.error
    );
  });
};

const handleAuthorizeReturn = (
  frame: ModalFrame,
  data?: AuthorizeData,
  callback?: AuthorizeCallback
): void | AuthorizePromise => {
  const sendAuthorizeData = () => {
    // Forward the session context from the sdk.
    const { sessionId, purchaseCountry, locale, integrationType } =
      sdkState.getState();

    const isHPPSession = (
      session_context: AuthorizeData['session_context']
    ): session_context is DirectHppModalSession => {
      // cannot use discriminated type checks here as integration type is set on the FE and does not come from BE
      return !!(session_context as DirectHppModalSession)?.channel;
    };

    const isAutoconfirm = (d: AuthorizeData | undefined): boolean => {
      if (!d || !d.session_context) {
        return false;
      }

      if (isHppEcommerceSession(d.session_context)) {
        return d.session_context.autoconfirm || false;
      }

      return false;
    };

    const session_context = {
      token: sessionId,
      country: purchaseCountry,
      locale,
      currency: 'EUR',
      integrationType,
      ...(isHPPSession(data?.session_context)
        ? {
            channel: data?.session_context?.channel,
            confirmed: data?.session_context?.confirmed,
            autoconfirm: isAutoconfirm(data),
          }
        : null),
    } as const;

    const authorizeDataWithSessionContext: AuthorizeData = {
      ...data,
      session_context,
    };

    logger.log(
      'SDK sendAuthorizeData called with:',
      authorizeDataWithSessionContext
    );

    // SDK sending to modal
    messenger.publish(TOPICS.authorize, authorizeDataWithSessionContext);
  };

  if (callback) {
    return attachModalHandlers(
      frame,
      {
        onOpen: sendAuthorizeData,
        onClose: (result) => {
          callback(undefined, result);
        },
        onResult: (result) => {
          callback(undefined, result);
        },
        onError: (error) => {
          callback(error, undefined);
        },
      },
      MODAL_SCENARIOS.authorize
    );
  }

  return new Promise((resolve) => {
    attachModalHandlers(
      frame,
      {
        onOpen: sendAuthorizeData,
        onClose: (result) => {
          resolve([undefined, result]);
        },
        onResult: (result) => {
          resolve([undefined, result]);
        },
        onError: (error) => {
          resolve([error, undefined]);
        },
      },
      MODAL_SCENARIOS.authorize
    );
  });
};

const getAuthorizeParameters = (
  dataOrCallback?: AuthorizeDataOrCallback,
  authorizeCallback?: AuthorizeCallback
): AuthorizeParams => {
  let data: AuthorizeData | undefined;
  let callback: AuthorizeCallback | undefined;

  if (dataOrCallback !== undefined) {
    if (isCallback(dataOrCallback)) {
      callback = dataOrCallback;
    } else {
      data = dataOrCallback;
    }
  }

  if (authorizeCallback !== undefined) {
    callback = authorizeCallback;
  }

  return { data, callback };
};

export function authorize(): AuthorizePromise;
export function authorize(callback: AuthorizeCallback): void;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function authorize(data: AuthorizeData): AuthorizePromise;
export function authorize(
  data: AuthorizeData,
  callback: AuthorizeCallback
): void;

export function authorize(
  dataOrCallback?: AuthorizeDataOrCallback,
  authorizeCallback?: AuthorizeCallback
) {
  const { data, callback } = getAuthorizeParameters(
    dataOrCallback,
    authorizeCallback
  );
  const { uiConfig } = sdkState.getState();
  const showErrorModal = uiConfig?.showErrorModal;

  const validationError = validateParameters(data, callback);

  if (!showErrorModal && validationError !== undefined) {
    return handleErrorWithoutFrame(validationError, callback);
  }

  const modalFrame = openModalFrame(uiConfig);
  if (!modalFrame) {
    return handleErrorWithoutFrame(iFrameError, callback);
  }

  if (validationError !== undefined) {
    logger.error('validationError in SDK.Authorize', validationError);
    return handleAuthorizeError(modalFrame, validationError, callback);
  }

  return handleAuthorizeReturn(modalFrame, data, callback);
}
