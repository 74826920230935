import { CountryType } from '@checkout-ui/shared-domain-entities';

type FieldName = string;

export type ValidationRegex = Record<FieldName, RegExp>;

const enum InvalidReasons {
  REQUIRED = 'required',
  FORMAT = 'format',
}

type ValueToValidate = string | undefined;

export type IsValid = (
  country: CountryType
) => (
  fieldName: FieldName,
  value: ValueToValidate,
  isFieldOptional?: boolean
) => { valid: boolean; reason?: InvalidReasons };

const regex: Partial<Record<CountryType, ValidationRegex>> = {
  DE: {
    postal_code: new RegExp('^(\\d{5})?$'),
  },
  AT: {
    postal_code: new RegExp('^(\\d{4})?$'),
  },
};

export const isValid: IsValid =
  (country) => (fieldName, value, isFieldOptional) => {
    const isEmptyNonOptionalField = !isFieldOptional && !value?.trim();

    const isInvalidFormat = () => {
      const validationForCountry = regex[country];

      return Boolean(
        value &&
          validationForCountry &&
          validationForCountry[fieldName] &&
          !validationForCountry[fieldName].test(value)
      );
    };

    if (isFieldOptional) {
      return { valid: true };
    }

    if (isEmptyNonOptionalField) {
      return { valid: false, reason: InvalidReasons.REQUIRED };
    }

    if (isInvalidFormat()) {
      return { valid: false, reason: InvalidReasons.FORMAT };
    }

    return { valid: true };
  };
