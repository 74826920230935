import messenger from '@checkout-ui/shared/cross-document-messenger/messenger';
import { logger } from '@checkout-ui/shared-logger';

import { Events } from './types';

/**
 * Subscribes the SDK to multiple events, associating each event with a corresponding event handler.
 *
 * @param events - An object mapping event names to event handler functions.
 * @returns void
 *
 * @example
 * const events = {
 *   'event1': eventHandler1,
 *   'event2': eventHandler2,
 * };
 *
 * on(events);
 *
 */
export const on = (events: Events): void => {
  //FIXME: I think this messenger init fn should be in the sdk.init
  //? What will happen if it's already initialized
  const isReady = messenger.init({
    origin: '*',
  });

  if (!isReady) {
    logger.error('Modal messenger is not ready');
    return;
  }

  Object.entries(events).forEach(([event, handler]) => {
    messenger.subscribe(event, handler);
    logger.log(`SDK subscribed to ${event}`);
  });
};
